import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';
import { withCookies, Cookies } from 'react-cookie';
import Casino, { context } from '@dialinvest/react-casino';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as parsePhone from 'libphonenumber-js';
import SignupState, { previousState, SignupStateString } from './SignupState';
import SignupForm from './SignupForm';
import { accountDetailsConfig } from './configs/first';
import { addressDetailsConfig, personalDetailsConfig, acceptFieldsConfig } from './configs/second';
import {
  clearFieldsValues,
  countriesData,
  currenciesData,
  getKeyByValue,
  localesData,
  setDefaults,
  adjustCurrenciesData,
} from './signupHelpers';
import { AddTrackingValue } from './SignupAddEgass';
import { apiCall, clipHTMLBody } from '../../Helpers';
import dialData from './country-dial-code.json';
import store from '../../../containers/App/store';
import { setUserData, setUserLoggedIn } from '../../../redux/actions/userActions';
import { toggleModalGeneralError } from '../../../redux/actions/generalErrorModalActions';
//import { DefaultCmsApi } from '../../../lib/api/cms/defaultCmsApi';

export class Signup extends PureComponent {
  url = 'https://ff-ps.omegasys.eu/ps/ips/';

  constructor(props) {
    super(props);
    this.signup = new Casino.models.Signup();
    this.state = {
      loadedExternalData: false,
      signupState: SignupState.first,
      accountDetails: accountDetailsConfig,
      acceptFields: acceptFieldsConfig,
      personalDetails: personalDetailsConfig,
      addressDetails: addressDetailsConfig,
      isValidRecaptcha: false,
    };
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);
    this.getExternalData();
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  getExternalData = async () => {
    const { loadedExternalData } = this.state;
    const {
      t,
      onToggleModal,
      onGeneralErrorToggleModal,
    } = this.props;

    if (loadedExternalData === false) {
      const { availableLocales, i18n } = this.props;
      if (availableLocales) {
        this.setState(prevState => localesData(prevState, availableLocales, i18n.language, t));
      }

      const country = new Casino.models.Country();

      country.all().then((result) => {
        if (result.success()) {
          this.setState(prevState => countriesData(prevState, result.data.countries));
        }
      });

      country.getCountryInfo().then(async (result) => {
        if (result.success()) {
          const restrictedCountries = process.env.REACT_APP_REG_RESTRICTED_COUNTRIES.split(',');
          const { countryInfo: { iso2Code } } = result.data;

          if (restrictedCountries.includes(iso2Code)) {
            onToggleModal();
            onGeneralErrorToggleModal(t('errors:no_players_from_your_country'));
          }
          const currencyItems = await new Casino.GetCurrencies(iso2Code).perform();

          if (currencyItems.length !== 0) {
            this.setState(prevState => currenciesData(prevState, currencyItems, t));
          }

          this.setState(prevState => setDefaults(prevState, result.data));
        }
      });

      this.setState({ loadedExternalData: true });
    }
  };

  getFieldByKey = (key) => {
    const { state } = this;
    let field;

    if (state.accountDetails.fields[key]) {
      field = state.accountDetails.fields[key];
    } else if (state.acceptFields.fields[key]) {
      field = state.acceptFields.fields[key];
    } else if (state.personalDetails.fields[key]) {
      field = state.personalDetails.fields[key];
    } else if (state.addressDetails.fields[key]) {
      field = state.addressDetails.fields[key];
    }

    return field;
  };

  setFieldByKey = (key, field) => {
    const { state } = this;
    let updatingfields;

    if (state.accountDetails.fields[key]) {
      updatingfields = { ...state.accountDetails };
    } else if (state.acceptFields.fields[key]) {
      updatingfields = { ...state.acceptFields };
    } else if (state.personalDetails.fields[key]) {
      updatingfields = { ...state.personalDetails };
    } else if (state.addressDetails.fields[key]) {
      updatingfields = { ...state.addressDetails };
    }

    if (updatingfields) {
      updatingfields.fields[key] = field;
      this.setState({ updatingfields });
    }
  };

  setTouchedAs(updatingFields, value) {
    for (const index in updatingFields.fields) {
      updatingFields.fields[index].touched = value;
    }
    this.setState(updatingFields);
  }

  getDialCode(countryCode) {
    return dialData.filter(countryInfo => countryInfo.code === countryCode);
  }

  inlineChecking = (result, key) => {
    const field = this.getFieldByKey(key);
    if (field) {
      if (result.data.errors.length !== 0) {
        const errorKey = getKeyByValue(result.data.errors, key);

        if (errorKey) {
          const errorMessage = result.data.errors[errorKey].error;
          field.errorDescription = `errors:${errorMessage}`;
          field.valid = false;
        } else {
          field.onlineValid = true;
          field.valid = true;
        }
      } else {
        field.onlineValid = true;
        field.valid = true;
      }
      if (field.title === 'email' && field.valid) {
        field.valid = this.checkValidity(
          field.value, field.validation,
        );
      }

      this.setFieldByKey(key, field);
    }
  };

  inlineValidation = async (key) => {
    const result = await apiCall(this.signup.signup(this.prepareParameters()));
    this.inlineChecking(result, key);
  };

  inlinePhoneValidation = async () => {
    const { addressDetails } = this.state;
    const mobileField = addressDetails.fields.mobile;

    const params = {
      field: 'mobilePhone',
      value: mobileField.value,
    };
    const result = await new Casino.models.CasinoBase().request('get', 'checkCredential', params);

    mobileField.valid = !result.data.fieldExist;
    mobileField.onlineValid = !result.data.fieldExist;
    if (result.data.fieldExist) mobileField.errorDescription = 'errors:DUPLICATE';

    this.setFieldByKey('mobile', mobileField);
  };

  clearEmailConfirmation = (value) => {
    const key = 'emailConfirmation';
    const field = this.getFieldByKey(key);
    if (value !== field.value) {
      field.valid = false;
    } else {
      field.valid = true;
    }
    this.setFieldByKey(key, field);
  };

  setCountryField = async (field) => {
    const { t } = this.props;
    const country = this.getFieldByKey('country');
    const mobile = this.getFieldByKey('mobile');
    const countryInfo = this.getDialCode(country.value);
    mobile.value = countryInfo.length > 0 ? countryInfo[0].dial_code : '';
    mobile.valid = false;
    mobile.touched = false;
    this.setFieldByKey('mobile', mobile);

    const currency = this.getFieldByKey('currency');
    let newItems = [];

    await new Casino.GetCurrencies(field.value).perform().then((currencyItems) => {
      newItems = adjustCurrenciesData(currencyItems, t);
    });

    currency.value = newItems[0].value;

    // eslint-disable-next-line array-callback-return
    Object.entries(currency.currencyDefaultRules).map(([currencyKey, currencyItem]) => {
      if (currencyKey === field.value) {
        newItems.forEach((item) => {
          if (item.value === currencyItem) {
            currency.value = currencyItem;
          }
        });
      }
    });
    currency.items = newItems;

    this.setFieldByKey('currency', currency);
  };

  inputChangedHandler = async (event, key) => {
    const field = this.getFieldByKey(key);
    let validationResult = false;
    if (field) {
      if (field.elementType === 'checkbox') {
        field.value = !field.value;
        field.valid = field.value;
      } else {
        field.value = event.target.value;
        if (field.title === 'email') {
          this.clearEmailConfirmation(field.value);
        }
        if (field.title === 'country') {
          this.setCountryField(field);
        }

        if (field.title !== 'mobile') {
          validationResult = this.checkValidity(
            field.value, field.validation,
          );
        }
        if (field.title === 'mobile') {
          field.value = `+${field.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1')}`;

          const phoneNumber = parsePhone.parsePhoneNumberFromString(field.value);

          if (phoneNumber && phoneNumber.isValid()) {
            field.corrected = (field.value !== phoneNumber.number);
            field.value = phoneNumber.number;
            validationResult = true;
          } else {
            field.corrected = false;
          }
        }
        field.valid = validationResult;
        if (!validationResult) {
          field.errorDescription = '';
        }
        if ((field.validation.inline) && validationResult) {
          field.onlineValid = false;

          if (field.title === 'mobile') {
            this.inlinePhoneValidation();
          } else {
            this.inlineValidation(key);
          }
          /* if (field.title === 'email') {
            try {
              const domain = field?.value?.split('@')?.[1];
              const res = await DefaultCmsApi.checkDomain(domain);
              if (res) {
                field.errorDescription = 'errors:not_allowed_email_domain';
                field.onlineValid = true;
                field.valid = false;
              }
            } catch (error) {
              console.error(error)
            }
          } */
        }
      }
      field.touched = true;
      this.setFieldByKey(key, field);
    }
  };

  formatMobileForOmega = (mobile) => {
    if (mobile.charAt(0) === '+') {
      return mobile.slice(1);
    }

    return mobile.slice(2);
  };

  formatContactPreferenceForOmega = value => (value ? 'SMS' : 'EMAIL');

  prepareParametersForRequest = (fields) => {
    const formElementsArray = {};
    for (const key in fields) {
      if (fields[key].title === 'mobile') {
        formElementsArray[fields[key].title] = this.formatMobileForOmega(fields[key].value);
      } else if (fields[key].title === 'contactPreference') {
        formElementsArray[fields[key].title] = this.formatContactPreferenceForOmega(false);
      } else {
        formElementsArray[fields[key].title] = fields[key].value;
      }
    }

    formElementsArray.securityQuestion = 'What were the last 4 digits of your childhood telephone number?';
    formElementsArray.securityAnswer = '1234';
    formElementsArray.extraInfo = '{"SourceOfWealth": ""}';

    return AddTrackingValue(formElementsArray);
  };

  prepareParameters = () => {
    const { state } = this;
    const fieldsArray = {
      ...state.accountDetails.fields,
      ...state.acceptFields.fields,
      ...state.personalDetails.fields,
      ...state.addressDetails.fields,
    };
    return this.prepareParametersForRequest(fieldsArray);
  };

  validRequiredFields = (fields) => {
    for (const index in fields) {
      if (fields[index].validation.required && !fields[index].valid) {
        return true;
      }
    }
    return false;
  };

  validRequiredFieldsForSignupRequest = () => {
    const { state } = this;
    const fieldsArray = {
      ...state.personalDetails.fields,
      ...state.addressDetails.fields,
      ...state.acceptFields.fields,

    };

    if (this.validRequiredFields(fieldsArray)) {
      this.setTouchedAs({ ...state.personalDetails }, true);
      this.setTouchedAs({ ...state.addressDetails }, true);
      this.setTouchedAs({ ...state.acceptFields }, true);

      return true;
    }

    return false;
  };

  validRequiredFieldsForPreSignupRequest = () => {
    const { state } = this;
    const fieldsArray = {
      ...state.accountDetails.fields,
    };

    if (this.validRequiredFields(fieldsArray)) {
      this.setTouchedAs({ ...state.accountDetails }, true);

      return true;
    }

    return false;
  };

  signupStep = async () => {
    if (this.validRequiredFieldsForSignupRequest()) {
      return;
    }

    const params = this.prepareParameters();
    let notFoundFieldError = false;

    this.setState({ signupState: SignupState.loading });
    const result = await this.signup.signup(params);

    if (!result.success()) {
      result.data.errors.map((item) => {
        const field = this.getFieldByKey(item.field);
        if (field) {
          notFoundFieldError = true;
          field.valid = false;
          field.errorDescription = `errors:${item.error}`;
          this.setFieldByKey(item.field, field);
        }
        return true;
      });
      if (!notFoundFieldError) {
        this.setState({ signupState: SignupState.error });
      } else {
        this.setState({ signupState: SignupState.first });
      }
    } else {
      this.setState({ signupState: SignupState.third });
    }
  };

  preSignupStep = () => {
    if (this.validRequiredFieldsForPreSignupRequest()) {
      return;
    }

    this.setState({ signupState: SignupState.second });
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    const { state } = this;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.pattern) {
      isValid = rules.pattern.test(value) && isValid;
    }

    if (rules.confirm) {
      isValid = (value === state.accountDetails.fields.email.value && isValid);
    }

    if (rules.maxDate) {
      isValid = moment(value).isBefore(rules.maxDate) && isValid;
    }

    return isValid;
  }

  finalStep = () => {
    this.amlCheck();
    if (process.env.REACT_APP_REGISTRATION_AUTO_LOGIN === 'true') this.autoLogin();
    this.setState({ signupState: SignupState.fourth });
  };

  sendUserConsents = (item, account, userConsents, sessionKey) => {
    const consentData = userConsents.find(
      consent => consent.consent.key === item.consentKey,
    );
    if (!consentData) return;

    if (item.value) {
      account.userConsentsSave(
        { sessionKey, consentedVersions: consentData.consent.consentVersionId },
      );
    } else {
      account.userConsentsSave(
        { sessionKey, unConsentedVersions: consentData.consent.consentVersionId },
      );
    }
  }

  updateUserConsents = async (sessionKey) => {
    const { acceptFields } = this.state;
    const account = new Casino.models.Account();

    const result = await account.userConsents({
      sessionKey,
    });
    if (result.data.userConsents) {
      Object.entries(acceptFields.fields).map(([, item]) => (
        this.sendUserConsents(item, account, result.data.userConsents, sessionKey)
      ));
    }
  };

  autoLogin = async () => {
    const { updateUser, user, storeUserData } = this.props;
    const { accountDetails } = this.state;
    const { username, password } = accountDetails.fields;
    const userConsents = {
      tac: true,
      pp: true,
    };

    const result = await new Casino.models
      .Signin(username.value, password.value, null, userConsents).perform();

    if (result.success()) {
      const sessionCookie = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);

      this.updateUserConsents(result.data.sessionKey);
      store.dispatch(setUserLoggedIn(true));

      const userObj = {
        ...user,
        tac: sessionCookie.tac,
        pp: sessionCookie.pp,
        email: sessionCookie.email,
        sessionKey: sessionCookie.sessionKey,
        sourceOfWealth: '',
        rec_emails: sessionCookie.rec_emails,
        username: sessionCookie.username,
        partyId: sessionCookie.partyId,
      };

      updateUser(userObj);
      storeUserData(userObj);
    }
  };

  amlCheck = async () => {
    const { personalDetails, accountDetails, addressDetails } = this.state;

    const playerDetails = {
      id: accountDetails.fields.username.value,
      given_name: personalDetails.fields.firstName.value,
      surname: personalDetails.fields.lastName.value,
      birthdate: accountDetails.fields.birthDate.value,
      country: addressDetails.fields.country.value,
      site_name: 'omnislots',
    };

    await new Casino.AmlCheck(playerDetails).perform();
  };

  nextButton = () => {
    const { state } = this;

    this.storeSignUpState(state.signupState);

    if (state.signupState === SignupState.first) {
      this.preSignupStep();
    } else if (state.signupState === SignupState.second) {
      this.signupStep();
    } else if (state.signupState === SignupState.third) {
      this.finalStep();
    }
  };

  storeSignUpState = (state) => {
    const { cookies } = this.props;

    cookies.remove('signupState', { path: '/' });
    cookies.set('signupState', SignupStateString[state], { path: '/' });
  };

  previousButton = () => {
    const { state } = this;
    const newState = previousState(state.signupState);
    this.setState({ signupState: newState });
  };

  closeButton = async () => {
    const { props } = this;
    await this.setState(prevState => clearFieldsValues(prevState));
    props.onToggleModal();
    clipHTMLBody();
  };

  cancelButton = async () => {
    const { props } = this;
    await this.setState(prevState => clearFieldsValues(prevState));
    props.onToggleModal();
    clipHTMLBody();
  };

  escFunction = (event) => {
    const { props } = this;
    if (event.keyCode === 27 && props.isOpen) {
      // Do whatever when esc is pressed
      props.onToggleModal();
      clipHTMLBody();
    }
  };

  onRecaptchaValidation = () => this.setState({ isValidRecaptcha: true });

  renderSignupForm = () => {
    const { state } = this;
    return (
      <SignupForm
        signupState={state.signupState}
        accountDetails={state.accountDetails}
        acceptFields={state.acceptFields}
        personalDetails={state.personalDetails}
        addressDetails={state.addressDetails}
        inputChangedHandler={this.inputChangedHandler.bind(this)}
        nextButton={this.nextButton}
        previousButton={this.previousButton}
        closeButton={this.closeButton}
        cancelButton={this.cancelButton}
        isValidRecaptcha={state.isValidRecaptcha}
      />
    );
  }

  render() {
    const { props } = this;

    if (props.isOpen === false) {
      return '';
    }
    const signupForm = this.renderSignupForm();

    return (
      <div id="modal-signup" className="modal m-modal-base modal-fx-superScaled m-modal-fixed--mobile m-modal-signup is-active">
        <div className="modal-background" />
        {signupForm}
        <GoogleReCaptcha onVerify={this.onRecaptchaValidation} />
      </div>
    );
  }
}

Signup.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
  onGeneralErrorToggleModal: PropTypes.func.isRequired,
  storeUserData: PropTypes.func.isRequired,
  availableLocales: PropTypes.instanceOf([]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

export const mapStateToProps = state => ({
  isOpen: state.signUpModal?.isOpen,
  availableLocales: state.locales.availableLocales,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModal: () => dispatch({ type: 'TOGGLE_MODAL' }),
  onGeneralErrorToggleModal: message => dispatch(toggleModalGeneralError(message)),
  storeUserData: payload => dispatch(setUserData(payload)),
});

export default compose(
  context.withUserHOC,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('errors'),
  withCookies,
)(Signup);
